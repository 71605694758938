<template>
    <a-layout>
        <a-layout-sider :trigger="null" collapsible>
            <mymenu />
        </a-layout-sider>
        <a-layout>

            <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }">
                <!-- 内容填充区 -->
                <!-- {{ put_from_now }} <br> -->
                <!-- {{ data1 }} -->
                <br>

                <a-select v-model:value="cate" style="width: 120px" :options="f_name" placeholder="{{cate}}" />

                <a-form ref="formRef" name="dynamic_form_item" :model="put_from_now.domains">
                    <a-form-item v-for="(domain, index) in put_from_now.domains" :key="index" :rules="{
                        required: true,
                        message: 'domain can not be null',
                        trigger: 'change',
                    }">
                        <a-input v-model:value="domain.value" placeholder="请输入特定的json数据"
                            style="width: 60%; margin-right: 8px" />
                        <MinusCircleOutlined v-if="put_from_now.domains.length >= 1" class="dynamic-delete-button"
                            :disabled="put_from_now.domains.length === 1" @click="removeDomain(domain)" />
                    </a-form-item>
                    <a-form-item>
                        <a-button type="dashed" style="width: 60%" @click="addDomain">
                            <PlusOutlined />
                            Add field
                        </a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" html-type="submit" @click="submitForm">Submit</a-button>
                        <a-button style="margin-left: 10px" @click="resetForm">Reset</a-button>
                    </a-form-item>
                </a-form>

                <!-- 结束位置 -->
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
import menu from "./menu.vue";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';
// import { reactive, ref } from 'vue';

export default {
    components: {
        mymenu: menu,
        MinusCircleOutlined,
        PlusOutlined,
    },

    data() {
        return {
            key: "value",
            // 已经提交过的数据
            data1: [],
            cate: '游戏厂商',
            name: this.cate,
            f_name: [{ 'value': "游戏厂商", "lable": '1' }, { 'value': "电脑厂商", "lable": '2' }, { 'value': "汽车厂商", "lable": '3' }],
            put_from_now: {
                domains: [
                    // { value: "" }
                ],
            },

            // formRef: ref()
        }
    },
    methods: {
        getDomain() {
            this.myaxios('/put_from/', "get", { "name": this.cate }).then((res) => {
                console.log(res);

                if (JSON.parse(res.data).length >= 1) {
                    console.log(res.data);
                    this.data1 = JSON.parse(res.data)
                    this.put_from_now.domains = JSON.parse(res.data)
                }
                else {
                    this.data1 = []
                }

            }).catch((err) => {

            });
            this.data1
        },
        addDomain() {
            this.put_from_now.domains.push({
                value: '',
            });
        },

        removeDomain(item) {
            let index = this.put_from_now.domains.indexOf(item);
            if (index !== -1) {
                this.put_from_now.domains.splice(index, 1);
            }
        },

        submitForm() {
            var form_str = []
            for (var i = this.put_from_now.domains.length - 1; i >= 0; i--) {
                form_str.push(JSON.parse(this.put_from_now.domains[i]["value"]))
            }
            console.log("form_str===>", JSON.stringify(form_str));
            console.log({ "name": this.cate, 'from': JSON.stringify(form_str) });
            this.myaxios('/put_from/', 'post', { "name": this.cate, 'form': JSON.stringify(this.put_from_now.domains) }).then((res) => {
                // this.myaxios('/put_from/', 'post', { "name": , 'form': JSON.stringify(form_str) }).then((res) => {
                console.log(res);
            }).catch((err) => {
                console.log(err);
            });
        },

        resetForm() {
            let aa = JSON.parse(JSON.stringify(this.data1))
            this.put_from_now.domains = aa
        },

    },
    mounted() {
        this.getDomain();
    },
    created() { },
    setup() {

    },

}
</script>

<style scoped>
@import url('../assets/style.css');
</style>